<template>
  <div>
    <div class="info">
      <div class="jobshwheader"></div>
      <div class="min_body">
        <div class="job_describe">
          <!-- 职位要求及待遇岗位 -->
          <div class="job_describe_top">
            <div class="jjxx">综治办平台事件下发</div>
          </div>
          <div class="sj_job_box mt10"></div>
          <div class="job_describe_cengter">
            <div class="job_describe_cengter_header">{{ info.QDDX_MC }}</div>

            <div class="user_undergo_box">
              <div class="user_undergo user_undergo_a">
                事件类型：{{ info.kind_name }}
              </div>
              <div class="user_undergo user_undergo_a">
                发生点位：{{ info.camera_name }}
              </div>
              <div class="user_undergo user_undergo_a">
                首次告警时间：{{ info.timestamp }}
              </div>
              <div class="user_undergo user_undergo_a">
                车牌号：{{ info.carnum }}
              </div>
              <div class="user_undergo user_undergo_a">
                当前状态：{{ info.This_Status_Name }}
              </div>
              <div class="user_undergo user_undergo_a">
                下派中队：{{ info.Dept_Name }}
              </div>
              <div class="user_undergo user_undergo_a">
                下派网格员：{{ info.Staff_Name }}
              </div>
              <div class="user_undergo user_undergo_a">
                图片信息：
              </div>
              <img
                v-for="(item, i) in info.FileList"
                :key="i"
                :src="item.Url"
                style="width: 80px;height:80px;display: inline-block;padding: 2px"
                @click="previewBefore"
              />
            </div>
          </div>
          <div>
            <van-form>
              <team-cell
                ref="cell"
                :team-id="teamID"
                :cell-id="cellID"
                @teamCellChange="teamCellChange"
              />
              <cell-person
                ref="person"
                :cell-id="cellID"
                :person-id="personID"
                @cellPersonChange="cellPersonChange"
              />
              <div style="margin: 16px;">
                <van-button round block type="info" @click="Save()">
                  下发
                </van-button>
              </div>
            </van-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import { systemUrl } from '@/config'
  import TeamCell from '@/components/TeamCell'
  import CellPerson from '@/components/CellPerson'
  import { Dialog, ImagePreview, Toast } from 'vant'
  import { officeEventInfo, officeEventIssued } from '@/api/ist/ist/ist'
  import { Emas } from '@/assets/js/Emas'
  export default {
    name: 'IstEventIssued',
    components: { TeamCell, CellPerson },
    data() {
      return {
        pkValue: '',
        info: {}, //详情数据
        fileList: [],
        formData: {
          pkValue: '',
          istID: '',
          seqID: '',
          nodeID: '',
          UserID: 0,
          arrs: '',
        },
        hTyy: '',
        teamID: null,
        cellID: null,
        personID: null,
      }
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.formData.UserID = userInfo.User_ID
      this.pkValue = Base64.decode(this.$route.query.pkValue)
      this.formData.pkValue = this.pkValue
      this.formData.istID = Base64.decode(this.$route.query.istID)
      this.formData.seqID = Base64.decode(this.$route.query.seqID)
      this.formData.nodeID = Base64.decode(this.$route.query.nodeID)
      this.teamID = teamInfo.Team_ID
      this.fetchData()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'istEventIssued',
        '城管-平台事件内勤下发',
        'zzdcg.yy.gov.cn/istEventIssued'
      )
    },
    methods: {
      //获取行政审批详情
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        console.log(this.pkValue)
        const { data } = await officeEventInfo({ PK_Value: this.pkValue })
        console.log(data)
        let fileList = data.File
        if (fileList.length > 0) {
          for (let i = 0; i < fileList.length; i++) {
            fileList[i].Url = systemUrl + fileList[i].Url
            this.fileList.push(fileList[i].Url)
          }
        }
        data.FileList = fileList
        this.info = data
        console.log(this.info)
        Toast.clear()
      },
      async Save() {
        //行政审批下发
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        this.formData.arrs = this.personID

        console.log(this.formData)
        const { success, data, msg } = await officeEventIssued(this.formData)
        if (success) {
          Toast.clear()
          Dialog({ message: msg })
          this.onClickRight()
        } else {
          Toast.clear()
          Dialog({ message: msg })
        }
      },
      previewBefore() {
        ImagePreview({
          images: this.fileList,
        })
      },
      getFileList(val) {
        console.log(val)
        this.fileList = val
      },
      //网格选择
      teamCellChange(val) {
        this.$refs['person'].reset()
        this.personID = 0
        this.cellID = val
      },

      //人员选择
      cellPersonChange(val) {
        this.personID = val
      },
      //跳转到流程列表
      onClickRight() {
        this.$router.push({
          path: '/istIndex',
        })
      },
    },
  }
</script>

<style scoped>
  .job_describe_cengter {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }
  .job_describe_cengter2 {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }

  .job_describe_cengter_header {
    font-size: 0.426667rem;
    font-weight: 700;
    color: #181818;
  }

  .job_describe_top {
    position: relative;
    width: 100%;
    padding: 0.273333rem 0.373333rem;
    font-size: 0.373333rem;
    background-color: #fff;
    border-bottom: 0.013333rem solid #eee;
  }
  .jjxx {
    position: relative;
    padding-left: 0.473333rem;
    font-size: 0.426667rem;
    font-weight: bold;
  }
  .jjxx::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../../assets/images/workBench/detail.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
  .job_describe {
    width: 100%;
    margin-top: -1.333333rem;
    overflow: hidden;
    line-height: 1.8;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  .min_body {
    padding-right: 0.32rem;
    padding-left: 0.32rem;
  }
  .jobshwheader {
    width: 100%;
    height: 2.026667rem;
    font-size: 0.32rem;
    background-color: #2778f8;
  }
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user_undergo2 {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 2;
    color: #333;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background-size: auto;
    background-size: 100%;
  }
  .corporate_information {
    margin-top: 0.266667rem;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
</style>
